import React from 'react'
import styled from '@emotion/styled'
import { StaticImage } from 'gatsby-plugin-image'

import LayoutDAI from '../../../components/LayoutDAI'

import Hero from '../../../components/dai/Hero'
import Section from '../../../components/dai/Section'
import IconCard from '../../../components/dai/IconCard'
import Quote from '../../../components/dai/Quote'
import { Heading1 } from '../../../components/dai/Heading1'
import { Heading2 } from '../../../components/dai/Heading2'
import { Text } from '../../../components/dai/Text'
import Columns from '../../../components/dai/Columns'
import Column from '../../../components/dai/Column'
import PageNavigation from '../../../components/dai/PageNavigation'
import { BoldText } from '../../../components/dai/BoldText'
import { Button } from '../../../components/dai/Button'

import SEO from '../../../components/SEO'
import Space from '../../../components/common/Space'
import TextLink from '../../../components/common/TextLink'

import ApplicationWindow from '../../../images/dai/icons/assets/application-window.svg'
import AppendClip from '../../../images/dai/icons/assets/append-clip.svg'
import Keyboard from '../../../images/dai/icons/assets/keyboard.svg'

const MaxImage = styled.div`
  max-width: 200px;
`

export default function FinancialServices() {
  return (
    <LayoutDAI
      freeAudit
      showIndustryReports
      pagePath={{
        parent: 'industry-reports',
        child: 'finance',
        freeAuditHeading: 'Find out how your site compares to other financial services sites.',
      }}
    >
      <SEO
        title="Financial Services Accessibility | 2023 Digital Accessibility Index"
        pathname="/digital-accessibility-index/industry-reports/finance/"
        desc="Our expert review of top financial services sites found another 10 accessibility barriers across the pages tested, which included account pages and ATM/branch locators."
        bannerUrl="https://images.prismic.io/audioeye-web/659eab7b-a961-4d06-a33d-1ed118e289b3_finance.jpg?auto=compress,format"
        imageAlt="Stylized billboard for the Financial Services industry with a call to action that reads “Get started at link unknown.com”"
        canonical={`https://${
          process.env.GATSBY_ACTIVE_ENV === 'production' ? 'www' : 'beta'
        }.audioeye.com/digital-accessibility-index/industry-reports/finance/`}
      />
      <Hero
        image={
          <StaticImage
            src="../../../images/dai/hero/finance.png"
            alt="Stylized billboard for the Financial Services industry with a call to action that reads “Get started at link unknown.com”"
          />
        }
        backgroundColor="orange200"
        noPaddingBottomMobile
      >
        <Text largeCapsInter color="orange900">
          Financial Services
        </Text>
        <Space height={24} />
        <Heading1 heading1 alt aria-label="Financial Services">
          Accessibility barriers can make it easier to bank in-person.
        </Heading1>
        <Space height={32} />
        <Text>
          When we talked to members of the disability community about their experiences with online banking and
          investment sites, several noted that accessibility barriers can make it hard to manage their accounts online —
          forcing them to rely on in-person services or the help of friends, family, and even strangers.
        </Text>
        <Space height={32} />
        <Text>
          In fact, accessibility barriers are one of the reasons that{' '}
          <BoldText>
            just 37% of households with a disability used online or mobile as their primary method to access their
            account, compared with 62% of households with no disability
          </BoldText>{' '}
          (
          <TextLink
            to="https://www.nationaldisabilityinstitute.org/wp-content/uploads/2019/11/ndi-banking-report-2019.pdf"
            target="_blank"
            rel="noreferrer"
            text="National Disability Institute"
            anchorClassName="unstyled-link dai-link"
          />
          ).
        </Text>
      </Hero>
      <Section paddingTop="xl" constraint="lg" backgroundColor="orange200">
        <Quote
          quoteText="“For banking, both personally and for work, the first step is always evaluating the accessibility of their online portals. And if a company is consistently unwilling to improve things, then it’s kind of like ‘Nope, this isn’t going to work.’”"
          author="TJ Olsen"
          title="AudioEye A11iance Member and QA Tester"
        />
      </Section>
      <Section paddingTop="lg" constraint="lg" innerConstraint="md" backgroundColor="orange200">
        <Text largeCapsInter color="orange900">
          EXPERT AUDITS
        </Text>
        <Space height={32} />
        <Heading2 heading2 className="asH3">
          Breaking down the most significant barriers on the top finance sites.
        </Heading2>
        <Space height={32} />
        <Text>
          After reviewing the top financial services sites, our expert testers noted that keyboard accessibility issues
          made it hard for screen reader users to navigate around pop-up windows (such as promotional offers) or access
          information about account policies or perks.
        </Text>
        <Space height={32} />
        <Text>
          On average, our testers found another{' '}
          <BoldText>
            10 accessibility barriers across the pages tested, which included account pages and ATM/branch locators
          </BoldText>{' '}
          — including three consistent barriers that impacted their ability to sign up for or manage accounts.
        </Text>
      </Section>
      <Section paddingTop="md" constraint="lg" backgroundColor="orange200">
        <Columns orderedList>
          <Column size={12} listItem>
            <IconCard noCard>
              <ApplicationWindow />
              <Space height={16} />
              <Text heading3 className="asH4">
                1. Pop-up windows could not be closed using keyboard commands.
              </Text>
              <Space height={16} />
              <Text>
                Our experts noted multiple pop-up windows that could not be closed with a keyboard — meaning assistive
                technology users could get trapped inside the pop-up with no way to return to the main page content.
              </Text>
              <Space height={32} />
              <Text zIndex={2}>
                <BoldText>WCAG Criteria:</BoldText>{' '}
                <TextLink
                  to="https://www.w3.org/WAI/WCAG21/Understanding/keyboard.html"
                  target="_blank"
                  rel="noreferrer"
                  text="2.1.1: Keyboard"
                  anchorClassName="unstyled-link dai-link"
                />
              </Text>
              <Text>
                <BoldText>Disabilities Affected:</BoldText> Visual, Mobility
              </Text>
            </IconCard>
          </Column>
          <Column size={12} listItem>
            <IconCard noCard>
              <AppendClip />
              <Space height={16} />
              <Text heading3 className="asH4">
                2. Missing skip links made site navigation difficult for screen reader users.
              </Text>
              <Space height={16} />
              <Text>
                Our experts noted many pages were missing a “skip to main” link, which enables screen reader users to
                jump past a bunch of unwanted links (such as primary navigation links) and get to the main content on a
                page.
              </Text>
              <Space height={32} />
              <Text zIndex={2}>
                <BoldText>WCAG Criteria:</BoldText>{' '}
                <TextLink
                  to="https://www.w3.org/WAI/WCAG21/Understanding/bypass-blocks.html"
                  target="_blank"
                  rel="noreferrer"
                  text="2.4.1: Bypass Blocks"
                  anchorClassName="unstyled-link dai-link"
                />
              </Text>
              <Text>
                <BoldText>Disabilities Affected:</BoldText> Visual
              </Text>
            </IconCard>
          </Column>
          <Column size={12} listItem>
            <IconCard noCard>
              <Keyboard />
              <Space height={16} />
              <Text heading3 className="asH4">
                3. Keyboard accessibility issues prevented users from accessing key information.
              </Text>
              <Space height={16} />
              <Text>
                Our experts encountered several “accordion” elements (i.e., FAQ entries that can be expanded or
                collapsed by clicking a button) that did not respond to keyboard commands, which prevented screen reader
                users from accessing key information.
              </Text>
              <Space height={32} />
              <Text zIndex={2}>
                <BoldText>WCAG Criteria:</BoldText>{' '}
                <TextLink
                  to="https://www.w3.org/WAI/WCAG21/Understanding/name-role-value.html"
                  target="_blank"
                  rel="noreferrer"
                  text="4.1.2: Name, Role, Value"
                  anchorClassName="unstyled-link dai-link"
                />
              </Text>
              <Text>
                <BoldText>Disabilities Affected:</BoldText> Visual, Cognitive
              </Text>
            </IconCard>
          </Column>
        </Columns>
      </Section>
      <Section paddingTop="lg" constraint="lg" backgroundColor="orange200">
        <Quote
          quoteText="“A lot of times, there aren’t any steps to take to [maintain financial privacy]. You just have to accept that you’re about to give it up, because something you need to do isn’t optional.”"
          author="Chris Preiman"
          title="AudioEye A11iance Member & Internet Security Professional"
        />
      </Section>
      <Section paddingTop="lg" constraint="lg" innerConstraint="md" backgroundColor="orange200">
        <Text largeCapsInter color="orange900">
          AUTOMATED SCAN RESULTS
        </Text>
        <Space height={32} />
        <Text heading2 className="asH3">
          Most common issues, financial services sites.
        </Text>
        <Space height={32} />
        <Text>
          Our automated scan revealed a number of significant accessibility issues on financial services sites, with the
          most frequent offenders being issues related to site navigation and filling out forms.
        </Text>
      </Section>
      <Section paddingTop="lg" constraint="lg" backgroundColor="orange200">
        <Columns>
          <Column size={6} tabletSize={8}>
            <MaxImage>
              <StaticImage
                src="../../../images/dai/stat-sign/stop-sign-62.png"
                alt="Pie chart representing 62%. The pie chart is in the shape of a stop sign."
              />
            </MaxImage>
          </Column>
          <Column size={18} tabletSize={16}>
            <Text heading3 className="asH4">
              62% of pages on financial services sites had at least one link that did not clearly state where it would
              take users.
            </Text>
            <Space height={24} />
            <Text>
              For people with visual and cognitive disabilities, clear, descriptive link text is a critical part of
              being able to navigate websites.
            </Text>
            <Space height={24} />
            <Text zIndex={2}>
              <BoldText>WCAG Success Criteria:</BoldText>{' '}
              <TextLink
                to="https://www.w3.org/WAI/WCAG21/Understanding/link-purpose-in-context.html"
                target="_blank"
                rel="noreferrer"
                text="2.4.4: Link Purpose (In Context)"
                anchorClassName="unstyled-link dai-link"
              />
            </Text>
            <Text>
              <BoldText>Disabilities Affected:</BoldText> Visual, Cognitive
            </Text>
          </Column>
        </Columns>
      </Section>
      <Section paddingTop="md" constraint="lg" backgroundColor="orange200">
        <Columns>
          <Column size={6} tabletSize={8}>
            <MaxImage>
              <StaticImage
                src="../../../images/dai/stat-sign/stop-sign-37.png"
                alt="Pie chart representing 37%. The pie chart is in the shape of a stop sign."
              />
            </MaxImage>
          </Column>
          <Column size={18} tabletSize={16}>
            <Text heading3 className="asH4">
              37% of pages on financial services sites had at least one button that did not clearly state where it would
              take users.
            </Text>
            <Space height={24} />
            <Text>
              For people with visual and cognitive disabilities, clear, descriptive buttons is a critical part of being
              able to navigate websites.
            </Text>
            <Space height={24} />
            <Text zIndex={2}>
              <BoldText>WCAG Success Criteria:</BoldText>{' '}
              <TextLink
                to="https://www.w3.org/WAI/WCAG21/Understanding/link-purpose-in-context.html"
                target="_blank"
                rel="noreferrer"
                text="2.4.4: Link Purpose (In Context)"
                anchorClassName="unstyled-link dai-link"
              />
            </Text>
            <Text>
              <BoldText>Disabilities Affected:</BoldText> Visual, Cognitive
            </Text>
          </Column>
        </Columns>
      </Section>
      <Section paddingTop="md" paddingBottom="lg" constraint="lg" backgroundColor="orange200">
        <Columns>
          <Column size={6} tabletSize={8}>
            <MaxImage>
              <StaticImage
                src="../../../images/dai/stat-sign/stop-sign-40.png"
                alt="Pie chart representing 40%. The pie chart is in the shape of a stop sign."
              />
            </MaxImage>
          </Column>
          <Column size={18} tabletSize={16}>
            <Text heading3 className="asH4">
              40% of pages on financial services sites with a form had at least one field that was not labeled.
            </Text>
            <Space height={24} />
            <Text>
              Without descriptive field labels, it can be difficult for people with visual and cognitive impairments to
              sign up for an account or upload their personal information.
            </Text>
            <Space height={24} />
            <Text zIndex={2}>
              <BoldText>WCAG Success Criteria:</BoldText>{' '}
              <TextLink
                to="https://www.w3.org/WAI/WCAG21/Understanding/labels-or-instructions.html"
                target="_blank"
                rel="noreferrer"
                text="3.3.2: Labels or Instructions"
                anchorClassName="unstyled-link dai-link"
              />
            </Text>
            <Text>
              <BoldText>Disabilities Affected:</BoldText> Visual, Cognitive
            </Text>
          </Column>
        </Columns>
      </Section>
      <Section paddingTop="xl" paddingBottom="xl" constraint="lg" backgroundColor="orange300">
        <Columns>
          <Column size={16} tabletSize={14}>
            <Text heading3 className="asH4">
              Get expert insight into the accessibility of your top pages and customer portals.
            </Text>
            <Space height={32} />
            <Text>
              Uncover accessibility barriers that are making it hard for people with disabilities to manage their online
              banking needs with an expert audit of your site.
            </Text>
          </Column>
          <Column size={8} tabletSize={10} justifyCenter alignItemsEnd>
            <Button
              text="Schedule Expert Audit"
              to="/digital-accessibility-index/industry-reports/finance/#expert-audit"
              size="small"
              tag="DAI Page Content"
            />
          </Column>
        </Columns>
      </Section>
      <PageNavigation
        previousPageLink="/digital-accessibility-index/industry-reports/retail/"
        previousPageText="Retail"
        nextPageLink="/digital-accessibility-index/industry-reports/travel/"
        nextPageText="Travel"
      />
    </LayoutDAI>
  )
}
