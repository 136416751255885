/* eslint-disable indent */
import React from 'react'
import styled from '@emotion/styled'
import Text from '../common/Text'
import Space from '../common/Space'

const Wrapper = styled.div`
  border-radius: 24px;
  padding: 40px;
  background-color: ${props => props.theme.color.orange300};
`

const QuoteText = styled(Text)`
  font-size: 1.6rem;
  line-height: 2rem;
  color: ${props => props.theme.color.purple800};
  font-weight: bold;
`

const BoldText = styled.strong`
  font-weight: bold;
`

const Quote = ({ quoteText, author, title }) => (
  <Wrapper>
    <QuoteText>{quoteText}</QuoteText>
    <Space height={32} />
    <Text>
      <BoldText>{author}</BoldText> | {title}
    </Text>
  </Wrapper>
)

export default Quote
